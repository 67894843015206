import { FaGasPump, FaOilCan } from 'react-icons/fa'
import { HStack, StackDivider, Text } from '@chakra-ui/react'
import { useFeeData } from 'wagmi'

const GasSettings = () => {
  const { data } = useFeeData({
    formatUnits: 'gwei',
  })

  return (
    <HStack spacing={3} divider={<StackDivider />}>
      {!!data?.formatted.gasPrice && (
        <HStack justify={'center'} align={'center'}>
          <FaOilCan />
          <Text>{parseInt(data?.formatted.gasPrice).toFixed()} gwei</Text>
        </HStack>
      )}
      {!!data?.maxFeePerGas && !!data?.maxPriorityFeePerGas && (
        <HStack justify={'center'} align={'center'}>
          <FaGasPump />
          <Text textTransform={'uppercase'}>
            gas priority{' '}
            {!data?.formatted.maxPriorityFeePerGas
              ? '-'
              : parseInt(data?.formatted.maxPriorityFeePerGas).toFixed()}{' '}
            / max fee{' '}
            {!data?.formatted.maxFeePerGas ? '-' : parseInt(data?.formatted.maxFeePerGas).toFixed()}
          </Text>
        </HStack>
      )}
    </HStack>
  )
}

export default GasSettings
