import { useCallback } from 'react'
import { Button, useToast } from '@chakra-ui/react'
import { parseEther, toHex } from 'viem'
import { useAccount, useNetwork, useSendTransaction, useSwitchNetwork } from 'wagmi'
import WithTxConfirmation from '../../components/WithTxConfirmation'
import { useLoading } from '../../context/loading-context'
import useBoundStore from '../../store'
import { extractErrorMessage, hasNonHexData } from '../../utils/helper'

interface BtnProps {
  isDisabled: boolean
  insData: {
    networkId: number
    textData: string
    repeats: number
    recipient: string
  }
}
const InscribeBtn = ({ isDisabled, insData }: BtnProps) => {
  const { address } = useAccount()
  const { chain, chains } = useNetwork()
  const { switchNetwork, isLoading: isSwitching } = useSwitchNetwork()
  const { sendTransactionAsync } = useSendTransaction({
    value: parseEther('0'),
  })
  const toast = useToast({
    position: 'top-right',
    duration: 8000,
    isClosable: true,
  })
  const { setIsOverlayLoading, setLoadingText } = useLoading()
  const addTx = useBoundStore(state => state.addTx)

  const handleSubmitTransactions = useCallback(async () => {
    try {
      setIsOverlayLoading(true)
      setLoadingText(<WithTxConfirmation />)

      const td = insData?.textData
        ?.trim()
        ?.split('\n')
        .filter((val: string) => !!val?.trim())
      let txs = []
      if (td.length === 1) {
        txs = [...Array(insData.repeats).keys()].map(() =>
          sendTransactionAsync({
            to: address!,
            data: hasNonHexData(td[0]) ? toHex(td[0]) : (td[0] as `0x${string}`),
          })
        )
      } else {
        txs = td.map(val =>
          sendTransactionAsync({
            to: address!,
            data: hasNonHexData(val) ? toHex(val) : (val as `0x${string}`),
          })
        )
      }

      const receipts = await Promise.allSettled(txs)

      let transactions = []
      for (let i = 0; i < receipts.length; i++) {
        const r = receipts[i]
        if (r.status === 'fulfilled') {
          transactions.push(r.value?.hash)
          addTx({
            txHash: r.value?.hash,
            status: 'pending',
            createdAt: Date.now(),
            network: chain?.name || 'Ethereum',
            chainId: insData.networkId,
            paymentAddress: address!,
            recipientAddress: insData.recipient,
            type: 'ierc20',
          })
        }
        if (r.status === 'rejected') {
          toast({
            title: 'Inscription Error',
            status: 'error',
            description: extractErrorMessage(r.reason),
            duration: 5000,
          })
        }
      }

      if (transactions.length > 0) {
        toast({
          title: 'Inscription Submitted',
          status: 'info',
          description: `You've successfully submitted ${transactions.length} inscription transactions`,
        })
      }
    } catch (err) {
      console.error('Error occurs when submiting transactions:', err)
    } finally {
      setIsOverlayLoading(false)
    }
  }, [
    insData,
    address,
    sendTransactionAsync,
    toast,
    setIsOverlayLoading,
    setLoadingText,
    addTx,
    chain?.name,
  ])

  return (
    <>
      {chain?.unsupported || chain?.id !== insData.networkId ? (
        <Button
          minW={'10rem'}
          px={5}
          colorScheme="teal"
          variant="outline"
          fontSize={'lg'}
          isLoading={isSwitching}
          isDisabled={isSwitching}
          loadingText="Switching"
          onClick={() => switchNetwork?.(insData.networkId)}
        >
          Switch to {chains?.find(val => val?.id === insData?.networkId)?.name}
        </Button>
      ) : (
        <Button
          minW={'10rem'}
          px={5}
          colorScheme="teal"
          fontSize={'lg'}
          isDisabled={isDisabled || chain?.unsupported}
          onClick={handleSubmitTransactions}
        >
          Confirm
        </Button>
      )}
    </>
  )
}

export default InscribeBtn
