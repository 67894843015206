import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { formatUnits } from 'viem'
import { useContractRead, useNetwork } from 'wagmi'
import { aggregatorV3InterfaceABI } from '../../utils/constants'
import { constracts } from '../../utils/networks'

const TokenPrice = () => {
  const { chain } = useNetwork()

  const hasPriceFeed = useMemo(
    () => !!chain?.name && !!constracts[1].priceFeed[chain.nativeCurrency.symbol],
    [chain]
  )
  const { data: priceData } = useContractRead({
    address: constracts[1].priceFeed[chain?.nativeCurrency?.symbol || ''] as `0x${string}`,
    abi: aggregatorV3InterfaceABI,
    functionName: 'latestRoundData',
    enabled: hasPriceFeed,
    chainId: 1,
    // watch: true,
    cacheTime: 2_000,
  })

  return (
    <Text>
      {hasPriceFeed && !!priceData
        ? `$${parseFloat(formatUnits((priceData as any)?.[1], 8)).toFixed(2)}`
        : '-'}
    </Text>
  )
}

export default TokenPrice
