import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ClockLoader from 'react-spinners/ClockLoader'
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Layout } from '../components/Layout'
import { LoadingContext } from '../context/loading-context'
import { ErrorPage } from './Error'
import { Inscribe } from './Inscribe'

export const App = () => {
  const [isOverlayLoading, setIsOverlayLoading] = useState<boolean>(false)
  const [loadingText, setLoadingText] = useState<string>('')
  const bg = useColorModeValue('white', '#070627')
  const {
    isOpen: isLoadingModalOpen,
    onOpen: onLoadingModalOpen,
    onClose: onLoadingModalClose,
  } = useDisclosure()

  const LoadingOverlay = () => (
    <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="0.1rem" />
  )
  const [overlay] = useState(<LoadingOverlay />)

  useEffect(() => {
    if (isOverlayLoading) {
      onLoadingModalOpen()
    } else {
      onLoadingModalClose()
    }
  }, [isOverlayLoading, onLoadingModalClose, onLoadingModalOpen])

  return (
    <LoadingContext.Provider value={{ setIsOverlayLoading, setLoadingText }}>
      {isOverlayLoading && (
        <Modal
          isCentered
          isOpen={isLoadingModalOpen}
          onClose={onLoadingModalClose}
          closeOnOverlayClick={false}
        >
          {overlay}

          <ModalContent bgColor={bg} minW={'md'}>
            <ModalBody>
              <VStack my={8} rounded={'sm'}>
                <ClockLoader color="rgb(74, 211, 166)" size={40} />
                <Box>{loadingText}</Box>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Routes>
        <Route element={<Layout />} errorElement={<ErrorPage />}>
          <Route path="/" element={<Inscribe />} errorElement={<ErrorPage />} />
          <Route
            path="/inscribing"
            element={<Navigate to="/" replace />}
            errorElement={<ErrorPage />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </LoadingContext.Provider>
  )
}
