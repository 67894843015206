import { useNavigate } from 'react-router-dom'
import { Box, Container, Flex, Heading } from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'

const Header = () => {
  const navigate = useNavigate()

  return (
    <Box py={4} borderWidth={1} borderX={0}>
      <Container maxW={'7xl'}>
        <Flex justifyContent="space-between">
          <Heading as="h5" size="md" onClick={() => navigate('/')} cursor={'pointer'}>
            BitIns
          </Heading>
          <ConnectButton
            showBalance={{
              smallScreen: false,
              largeScreen: true,
            }}
            accountStatus={{
              smallScreen: 'avatar',
              largeScreen: 'full',
            }}
          />
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
