import { useMemo } from 'react'
import { FaDiscord, FaGithub, FaTwitter } from 'react-icons/fa'
import {
  Avatar,
  Container,
  Flex,
  HStack,
  Hide,
  Stack,
  StackDivider,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import { useNetwork } from 'wagmi'
import { networks } from '../../utils/networks'
import { ColorModeSwitcher } from '../ColorModeSwitcher'
import GasSettings from './GasSettings'
import TokenPrice from './TokenPrice'

const Footer = () => {
  const bgColor = useColorModeValue('white', '#14151e')
  const { chain } = useNetwork()
  const currNetwork = useMemo(() => networks.find(n => n.id === (chain?.id ?? 1)), [chain?.id])

  return (
    <>
      <Stack
        bg={bgColor}
        borderWidth={1}
        borderX={0}
        fontSize={14}
        position={'fixed'}
        bottom={0}
        w={'100%'}
        lineHeight={14}
      >
        <Container>
          <Flex justify={'space-between'} h={'2rem'} lineHeight={2}>
            <HStack spacing={3} divider={<StackDivider />}>
              {/* <HStack spacing={1} justify={'center'} align={'center'} color={'green'}>
                <FaSignal size={15} />
                <Text textTransform={'uppercase'} color={'green'}>
                  Latest block: 209384
                </Text>
              </HStack> */}

              <HStack spacing={3}>
                <Tooltip hasArrow label="Coming soon">
                  <Flex align={'center'} as={'a'} href={'#'}>
                    <FaDiscord size={20} />
                  </Flex>
                </Tooltip>
                <Flex align={'center'} as={'a'} href={'https://github.com/bit-ins'} target="_blank">
                  <FaGithub size={20} />
                </Flex>
                {/* https://x.com/bit_ins */}
                <Flex align={'center'} as={'a'} href={'#'} target="_blank">
                  <FaTwitter size={20} />
                </Flex>
              </HStack>

              <Flex justify={'center'} align={'center'}>
                <Avatar
                  h={'1.1rem'}
                  w={'1.1rem'}
                  mr={1}
                  size="xs"
                  src={(currNetwork?.iconUrl || currNetwork?.iconBackground) as string}
                />
                <TokenPrice />
              </Flex>
              <ColorModeSwitcher justifySelf="flex-end" />
              {/* <Settings /> */}
            </HStack>

            <Hide breakpoint="(max-width: 1102px)">
              <GasSettings />
            </Hide>
          </Flex>
        </Container>
      </Stack>
    </>
  )
}

export default Footer
