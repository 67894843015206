import { FC } from 'react'
import { FaMoon, FaSun } from 'react-icons/fa'
import { Flex, Icon, IconProps, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react'

type ColorModeSwitcherProps = Omit<IconProps, 'aria-label'>

export const ColorModeSwitcher: FC<ColorModeSwitcherProps> = props => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <>
      <Tooltip label={`Switch to ${text} mode`} hasArrow placement="top" arrowSize={15} mt={5}>
        <Flex align={'center'} py={3}>
          <Icon
            as={SwitchIcon}
            h={'1.1rem'}
            w={'1.1rem'}
            cursor={'pointer'}
            onClick={toggleColorMode}
            aria-label={`Switch to ${text} mode`}
            {...props}
          />
        </Flex>
      </Tooltip>
    </>
  )
}
