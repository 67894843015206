import { isHex } from 'viem'

export const extractErrorMessage = (error: any) => {
  if (error?.cause?.shortMessage) {
    return error?.cause?.shortMessage
  } else if (error?.details) {
    return error?.details
  } else if (error?.reason) {
    return error?.reason
  } else if (error?.error?.message) {
    return error?.error?.message
  } else if (error?.message) {
    return error?.message
  } else if (error?.name) {
    return error?.name
  }
  return error
}

export const shortenDid = (did: string) => {
  return `${did.slice(0, 5)}...${did.slice(-5)}`
}

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number)

export const shortWalletAddress = (address?: string, length = 4) => {
  return address ? `${address.slice(0, length + 2)}...${address.slice(-length)}` : ''
}

export const shortenIfTransactionHash = (hash?: string, length = 4) => {
  return hash ? `${hash.slice(0, length + 2)}...${hash.slice(-length)}` : hash
}

export const hasNonHexData = (str: string) => {
  return str
    ?.trim()
    ?.split('\n')
    .some((val: string) => {
      const trimmedVal = val?.trim()
      return trimmedVal?.length === 1 || (trimmedVal?.length > 2 && !isHex(trimmedVal))
    })
}
