import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { theme } from '@chakra-ui/pro-theme'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
import '@fontsource/quantico'
import { RainbowKitProvider, getDefaultWallets, lightTheme } from '@rainbow-me/rainbowkit'
import '@rainbow-me/rainbowkit/styles.css'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { App } from './pages/App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { networks } from './utils/networks'

require('dotenv').config()

const proTheme = extendTheme(theme)
const extenstion = {
  colors: {
    ...proTheme.colors,
    brand: { ...proTheme.colors.teal, 900: '#1a365d', 800: '#153e75', 700: '#2a69ac' },
  },
  fonts: {
    heading:
      "'Quantico', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    body: "'Quantico', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
}
const myTheme = extendTheme(proTheme, extenstion)

const { chains, publicClient } = configureChains(networks, [publicProvider()])

const { connectors } = getDefaultWallets({
  appName: 'Bitins dApp',
  projectId: process.env.REACT_APP_WALLET_CONNECT_ID || '',
  chains,
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ChakraProvider theme={myTheme}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          appInfo={{
            appName: 'BitIns',
          }}
          theme={{
            ...lightTheme(),
            fonts: { body: 'Quantico' },
            radii: { ...lightTheme().radii },
            colors: { ...lightTheme().colors, accentColor: 'teal' },
          }}
          showRecentTransactions={false}
          chains={chains}
        >
          <BrowserRouter>
            <ColorModeScript />
            <App />
          </BrowserRouter>
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
