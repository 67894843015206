import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { ApplicationSlice } from '../typings/slices'
import createApplicationSlice from './application'

type Slices = ApplicationSlice

const useBoundStore = create<Slices>()(
  devtools(
    persist(
      immer((...a) => ({
        ...createApplicationSlice(...a),
      })),
      { name: process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'vericheq' : 'vericheq-test' }
    )
  )
)

export default useBoundStore
