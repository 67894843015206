import { Avatar } from '@chakra-ui/react'
import { Chain } from '@rainbow-me/rainbowkit'
import { ChakraStylesConfig, Select, chakraComponents } from 'chakra-react-select'
import { networks } from '../../utils/networks'

const networkOptions = networks.map((c: Chain) => ({
  label: c.name,
  value: c.id,
  icon: <Avatar mr={3} size="xs" src={(c.iconUrl || c.iconBackground) as string} />,
}))

const customComponents = {
  Option: ({ children, ...props }: any) => (
    <chakraComponents.Option {...props}>
      {props.data.icon} {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }: any) => (
    <chakraComponents.SingleValue {...props}>
      {props.data.icon}
      {children}
    </chakraComponents.SingleValue>
  ),
}

interface SelectionProps {
  selectedOption: number
  handleChange: (net: string) => void
}
const NetworkSelect = ({ selectedOption, handleChange }: SelectionProps) => {
  const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      background: 'none',
      p: 0,
      w: '2.5rem',
      '> svg': {
        transform: `rotate(${state.selectProps.menuIsOpen ? -180 : 0}deg)`,
      },
    }),
  }

  return (
    <>
      <Select
        name="network"
        options={networkOptions}
        placeholder="Select a network"
        onChange={(val: any) => handleChange(val.value)}
        selectedOptionColorScheme="teal"
        components={customComponents}
        chakraStyles={chakraStyles}
        value={networkOptions.find(n => n.value === selectedOption)}
        menuPosition="fixed"
      />
    </>
  )
}

export default NetworkSelect
