import { Box, Text } from '@chakra-ui/react'

interface Props {
  children?: any
}

const WithTxConfirmation = ({ children }: Props) => {
  return (
    <Box textAlign={'center'}>
      <Text fontSize={'x-large'}>Waiting For Confirmation</Text>
      <Text pt={2}>Please check your wallet and confirm the transaction(s).</Text>
      {children}
    </Box>
  )
}

export default WithTxConfirmation
