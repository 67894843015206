import { Outlet } from 'react-router-dom'
import { CheckIcon, CopyIcon } from '@chakra-ui/icons'
import { Box, Code, Container, Flex, Hide, Stack, Text, useClipboard } from '@chakra-ui/react'
import Footer from './Footer'
import Header from './Header'

export const Layout = () => {
  const {
    onCopy: onCopyEth,
    value: ethAddr,
    hasCopied: hasCopiedEth,
  } = useClipboard('0xb8d28bD22efACFb090354710ac9839411224690C')
  const {
    onCopy: onCopyBtc,
    value: btcAddr,
    hasCopied: hasCopiedBtc,
  } = useClipboard('bc1qnmeaw239qzgezy600mka7mcy6xlyjqe8g20zw3')

  return (
    <>
      <Header />

      <Container maxW={'2xl'} mt={5} mb={'4rem'}>
        <Outlet />

        <Box mt={{ base: '3rem' }}>
          <Text fontWeight={'bold'}>
            If you find BitIns useful, please consider supporting development. Thank you 🙏
          </Text>

          <Stack mt={2} direction={{ base: 'column', sm: 'row' }}>
            <Text w={'4rem'}>Eth:</Text>
            <Flex align={'center'} gap={1}>
              <Code>{ethAddr}</Code>
              {hasCopiedEth ? <CheckIcon /> : <CopyIcon cursor={'pointer'} onClick={onCopyEth} />}
            </Flex>
          </Stack>
          <Stack direction={{ base: 'column', sm: 'row' }} mt={{ base: 1, sm: 'auto' }}>
            <Text w={'4rem'}>Bitcoin:</Text>
            <Flex align={'center'} gap={1}>
              <Code>{btcAddr}</Code>
              {hasCopiedBtc ? <CheckIcon /> : <CopyIcon cursor={'pointer'} onClick={onCopyBtc} />}
            </Flex>
          </Stack>
        </Box>
      </Container>

      <Hide breakpoint="(max-width: 700px)">
        <Footer />
      </Hide>
    </>
  )
}
