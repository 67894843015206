import { produce } from 'immer'
import { StateCreator } from 'zustand'
import { ApplicationSlice } from '../typings/slices'

const createApplicationSlice: StateCreator<ApplicationSlice, [], [], ApplicationSlice> = (
  set,
  get
) => ({
  transactions: [],

  addTx: tx =>
    set(
      produce(state => {
        state.transactions.push(tx)
      })
    ),
})

export default createApplicationSlice
