import { useRouteError } from 'react-router-dom'
import { Center, Heading, ListItem, Text } from '@chakra-ui/react'

export const ErrorPage = () => {
  const error = useRouteError()

  return (
    <Center flexDirection={'column'} textAlign={'center'} minH={'100vh'}>
      <Heading>Oops!</Heading>
      <Text>Sorry, an unexpected error has occurred.</Text>
      <Text>
        <ListItem>{(error as any).statusText || (error as any).message}</ListItem>
      </Text>
    </Center>
  )
}
