import { useCallback, useEffect, useMemo, useState } from 'react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import CodeMirror from '@uiw/react-codemirror'
import { EditorView } from 'codemirror'
import { useImmer } from 'use-immer'
import { getAddress } from 'viem'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import NetworkSelect from '../../components/Select/NetworkSelect'
import { networks } from '../../utils/networks'
import InscribeBtn from './InscribeBtn'

export const Inscribe = () => {
  const sliderBgColor = useColorModeValue('white', '#14151e')
  const sliderBorderColor = useColorModeValue('white', 'white')
  const { chains, switchNetwork } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  })
  const { address } = useAccount()
  const { chain } = useNetwork()
  const [isInvalidRecipient, setInvalidRecipient] = useState<boolean>()
  const [insData, setInsData] = useImmer<{
    networkId: number
    textData: string
    repeats: number
    recipient: string
    isClearedRecipient: boolean
  }>({
    networkId: chains?.[0]?.id ?? 1,
    textData: '',
    repeats: 1,
    recipient: address ?? '',
    isClearedRecipient: false,
  })

  useEffect(() => {
    if (!!address && !insData.recipient && !insData.isClearedRecipient) {
      setInsData(draft => {
        draft.recipient = address
      })
    }
  }, [address, insData.recipient, insData.isClearedRecipient, setInsData])

  useEffect(() => {
    if (chain && !chain.unsupported && chain.id !== insData.networkId) {
      setInsData(draft => {
        draft.networkId = chain.id
      })
    }
  }, [chain, insData.networkId, setInsData])

  const linesOfText = useMemo(
    () =>
      insData?.textData
        ?.trim()
        ?.split('\n')
        .filter((val: string) => !!val),
    [insData.textData]
  )
  const isInvalidText = useMemo(
    () => !!insData.textData && !insData.textData?.trim(),
    [insData.textData]
  )

  const handleRecipientChange = useCallback(
    (e: any) => {
      setInvalidRecipient(false)
      try {
        const val = (e.target.value as `0x${string}`) ?? address
        setInsData(draft => {
          draft.recipient = val
        })
        if (!val?.trim()) {
          throw Error('')
        }

        const checksumAddr = getAddress(val?.trim())
        setInsData(draft => {
          draft.recipient = checksumAddr
        })
      } catch (err) {
        setInvalidRecipient(true)
      }
    },
    [setInsData, address]
  )

  let myTheme = EditorView.theme({
    '.cm-scroller': { fontFamily: 'Quantico !important' },
    '&': {
      outline: 'none',
      border: `${isInvalidText ? '2px solid red' : '1px solid #E2E8F0'}`,
      borderRadius: '0.5rem',
    },
    '&.cm-focused': {
      outline: 'none',
      border: `2px solid ${isInvalidText ? 'red' : 'teal'}`,
    },
    '&.cm-focused .cm-cursor': {
      border: '2px solid teal',
    },
    '.cm-gutters': {
      fontFamily: 'monospace !important',
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
      textAlign: 'center',
    },
  })

  return (
    <>
      <VStack textAlign={'center'}>
        <Heading size={'md'}>Inscription Tool</Heading>
        <Text>Manage your inscriptions on any blockchain with ease</Text>
        <Text>Do your own research, at your own risk</Text>
      </VStack>

      <VStack my={5} gap={5}>
        <FormControl>
          <HStack justify={'space-between'}>
            <FormLabel fontSize={'md'}>Select network</FormLabel>
            <Text fontSize={'xs'}>supports {networks.length} chains</Text>
          </HStack>
          <NetworkSelect
            selectedOption={insData.networkId}
            handleChange={netId => {
              setInsData(draft => {
                draft.networkId = Number(netId)
              })
              if (!!netId && !!address) {
                switchNetwork?.(Number(netId))
              }
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'md'}>Inscription data</FormLabel>
          <CodeMirror
            onChange={val => {
              setInsData(draft => {
                draft.textData = val
              })
            }}
            theme={myTheme}
            placeholder={`data,{"p":"irc-20","op":"mint","tick":... \nor\n0x646174612c7b2270223a226972632d3230222c226f70223a22...`}
            value={insData.textData}
            height="10rem"
            extensions={[EditorView.lineWrapping]}
            spellCheck={false}
            basicSetup={{
              syntaxHighlighting: false,
            }}
          />
          <FormHelperText>
            We support both hex and text data, just put them on each seperate line, that's it.
          </FormHelperText>
          {isInvalidText && (
            <FormErrorMessage>Please enter a valid inscription data.</FormErrorMessage>
          )}
          {linesOfText?.length > 1 && (
            <FormHelperText mt={'auto'}>
              {linesOfText.length} of signature(s) required for inscription, max 100 txs each time.
            </FormHelperText>
          )}
        </FormControl>

        {linesOfText?.length <= 1 && (
          <FormControl>
            <FormLabel fontSize={'md'}>Repeat transactions</FormLabel>
            <Flex>
              <NumberInput
                size="sm"
                maxW="100px"
                mr="2rem"
                value={insData.repeats}
                onChange={val => {
                  if (!isNaN(+val)) {
                    setInsData(draft => {
                      draft.repeats = parseInt(val ?? 1)
                    })
                  }
                }}
                max={100}
                min={1}
                allowMouseWheel
              >
                <NumberInputField
                  h={9}
                  borderRadius={'lg'}
                  _focusVisible={{
                    borderColor: 'teal',
                    borderWidth: '2px',
                  }}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Slider
                flex="1"
                focusThumbOnChange={false}
                max={100}
                min={1}
                zIndex={0}
                colorScheme="teal"
                value={insData.repeats}
                onChange={val =>
                  setInsData(draft => {
                    draft.repeats = val
                  })
                }
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  bg={sliderBgColor}
                  borderColor={sliderBorderColor}
                  fontSize="sm"
                  boxSize="32px"
                  children={insData.repeats}
                />
              </Slider>
            </Flex>
            <FormHelperText>
              {insData.repeats} of signature(s) required for inscription, max 100 txs each time.
            </FormHelperText>
          </FormControl>
        )}

        <FormControl isInvalid={isInvalidRecipient}>
          <FormLabel fontSize={'md'}>Recipient address</FormLabel>
          <InputGroup>
            <Input
              value={insData.recipient}
              placeholder="Recipient address"
              onChange={handleRecipientChange}
              isInvalid={isInvalidRecipient}
              _focusVisible={{
                borderColor: isInvalidRecipient ? 'red' : 'teal',
                borderWidth: isInvalidRecipient ? '1px' : '2px',
              }}
            />
            <InputRightElement>
              <SmallCloseIcon
                cursor={'pointer'}
                onClick={() =>
                  setInsData(draft => {
                    draft.isClearedRecipient = true
                    draft.recipient = ''
                  })
                }
              />
            </InputRightElement>
          </InputGroup>
          {isInvalidRecipient ? (
            <FormErrorMessage>Invalid recipient address.</FormErrorMessage>
          ) : (
            <FormHelperText>The address you want to send the transaction(s) to.</FormHelperText>
          )}
        </FormControl>
      </VStack>

      <HStack justify={'center'} pt={15}>
        {!address ? (
          <ConnectButton />
        ) : (
          <InscribeBtn
            insData={insData}
            isDisabled={isInvalidRecipient || !insData.textData?.trim()}
          />
        )}
      </HStack>
    </>
  )
}
